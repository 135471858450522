
<template>
 <el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <div class="heading">
      <h1 v-if="!groupId">Add MLS Group</h1>
      <h1 v-if="groupId">Edit MLS Group</h1></div>
  </el-col>
  <el-col :xs="6" :sm="6" :md="6" :lg="12" :xl="12">
    <div class="topsearch">
      <el-button type="primary" @click="backonlist()">Back To Groups</el-button>
    </div>
  </el-col>
 </el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
  <div v-if="showAlertAdded">
    <el-alert
      title="MLS Group Added Successfully"
      type="success"
      effect="dark"
      v-if="!groupId"
    />
    <el-alert
        title="MLS Group Updated Successfully"
        type="success"
        effect="dark"
        v-if="groupId"
    />
  </div>
  <div v-if="showAlertSlugexit">
    <el-alert
      title="The slug has already been taken."
      type="error"
      effect="dark"
    />
  </div>
  </el-col>
</el-row>
    <el-form
      ref="ruleForm"
      :model="data"
      status-icon
      :rules="rules"
      label-width="180px"
      class=" login-form"
      :label-position="labelPosition" 
    >
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
        label="Name"
        prop="group_name"
      >
       <el-input
          v-model="data.group_name"
          type="text"
          autocomplete="off"
        />
      </el-form-item></el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
        label="Listing Group City"
        prop="city">
       <el-input
          v-model="data.city"
          type="text"
          autocomplete="off"
        />
      </el-form-item></el-col>
</el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"><el-form-item
        label="Add on Zone Group"
        prop="zonegroup"
      >
      <el-checkbox 
        v-model="data.zonegroup"
        class="form-inputcheck"
      />
      </el-form-item></el-col>
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Listed Days"
      prop="listed_on">
      <select name="listed_on" v-model="data.listed_on" class="selectstatus">
    <!--Add options when value is id-->
    <option v-for="item in selectlisted_on" 
            :key="item.label"
            :value="item.value"
            :label="item.label"
    >
      {{item.label}}
    </option>
  </select>
  </el-form-item></el-col>
 </el-row>
 <el-row :gutter="20">
   <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Bedrooms"
      prop="bedrooms">
      <select name="bedrooms" v-model="data.bedrooms" class="selectstatus">
    <!--Add options when value is id-->
    <option v-for="item in selectbedrooms" 
            :key="item.label"
            :value="item.value"
            :label="item.label"
    >
      {{item.label}}
    </option>
  </select>
  </el-form-item></el-col>
   <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Bathrooms"
      prop="bathrooms">
      <select name="bathrooms" v-model="data.bathrooms" class="selectstatus">
    <!--Add options when value is id-->
      <option v-for="item in selectbathrooms" 
            :key="item.label"
            :value="item.value"
            :label="item.label"
    >
      {{item.label}}
    </option>
  </select>
  </el-form-item></el-col>
  </el-row>
  <el-row :gutter="20">
   <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Type"
      prop="type">
      <select name="type" v-model="data.type" class="selectstatus">
    <!--Add options when value is id-->
    <option v-for="item in select_type" 
            :key="item.label"
            :value="item.value"
            :label="item.label"
    >
      {{item.label}}
    </option>
  </select>
  </el-form-item></el-col>
   <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Listing Type"
      prop="listing_type">
      <select name="listing_type" v-model="data.listing_type" class="selectstatus">
    <!--Add options when value is id-->
      <option v-for="item in selectlisting_type" 
            :key="item.label"
            :value="item.value"
            :label="item.label"
    >
      {{item.label}}
    </option>
  </select>
  </el-form-item></el-col>
  </el-row>
  <el-row :gutter="20">
   <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Rental Type"
      prop="rental_type">
      <select name="rental_type" v-model="data.rental_type" class="selectstatus">
    <!--Add options when value is id-->
    <option v-for="item in selectrental_type" 
            :key="item.label"
            :value="item.value"
            :label="item.label"
    >
      {{item.label}}
    </option>
  </select>
  </el-form-item></el-col>
   <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Lotsize"
      prop="lotsize">
       <el-input
          v-model="data.lotsize"
          type="text"
          autocomplete="off"
        />
  </el-form-item></el-col>
  </el-row>
  <el-row :gutter="20">
   <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Group Description"
      prop="group_description">
      <el-input
          v-model="data.group_description"
          type="textarea"
        />
  </el-form-item></el-col>
   <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Footer Description"
      prop="footer_description">
       <el-input
          v-model="data.footer_description"
          type="textarea"
        />
  </el-form-item></el-col>
  </el-row>
  <el-row :gutter="20">
   <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Seo Slug"
      prop="listing_groupslug">
      <el-input
          v-model="data.listing_groupslug"
          type="text"
          autocomplete="off"
        />
  </el-form-item></el-col>
   <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Meta Tag Title"
      prop="meta_title">
      <el-input
          v-model="data.meta_title"
          type="text"
          autocomplete="off"
        />
  </el-form-item></el-col>
  </el-row>
    <el-row :gutter="20">
   <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Meta Description"
      prop="meta_description">
      <el-input
          v-model="data.meta_description"
          type="textarea"
        />
  </el-form-item></el-col>
   <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Meta Keywords"
      prop="meta_keyword">
      <el-input
          v-model="data.meta_keyword"
          type="textarea"
        />
  </el-form-item></el-col>
  </el-row>
     <el-row :gutter="20">
   <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Min Price"
      prop="min_price">
      <el-input
          v-model="data.min_price"
          type="text"
          autocomplete="off"
        />
  </el-form-item></el-col>
   <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Max Price"
      prop="max_price">
      <el-input
          v-model="data.max_price"
          type="text"
          autocomplete="off"
        />
  </el-form-item></el-col>
  </el-row>
   <el-row :gutter="20">
   <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Surrounding Area"
      prop="surrounding_area">
      <el-input
          v-model="data.surrounding_area"
          type="text"
          autocomplete="off"
        />
  </el-form-item></el-col>
   <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Property Address"
      prop="propertyaddress">
      <el-input
          v-model="data.propertyaddress"
          type="text"
          autocomplete="off"
        />
  </el-form-item></el-col>
  </el-row>
  <el-row :gutter="20">
   <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Site Influences"
      prop="site_influences">
      <select name="site_influences" v-model="data.site_influences" class="selectstatus">
    <!--Add options when value is id-->
    <option v-for="item in selectinfluences" 
            :key="item.label"
            :value="item.value"
            :label="item.label"
    >
      {{item.label}}
    </option>
  </select>
  </el-form-item></el-col>
   <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> <el-form-item
      label="Status"
      prop="status">
       <select name="status" v-model="data.status" class="selectstatus">
    <!--Add options when value is id-->
    <option v-for="item in selectstatus" 
            :key="item.label"
            :value="item.value"
            :label="item.label"
    >
      {{item.label}}
    </option>
  </select>
  </el-form-item></el-col>
  </el-row>
<el-row :gutter="20">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
    <el-form-item 
        label="Upload Image"
        prop="listing_grouplogo"
      ><el-upload
            class="upload-demo"
            drag
            :action="imageUploadUrl"
            list-type="picture"
            :headers="imageUploadHeaders"
            name="file"
            :file-list="fileList"
            :limit="1"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleImageUploadSuccess"
            :on-error="handleImageUploadError"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">Drop file here or <em>click to upload</em><br>
          JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M</div>
           </el-upload>
      </el-form-item>
      </el-col>
</el-row> 
<el-row :gutter="20" style="text-align: left;">
  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <el-form-item>
        <input
          id="groupId"
          v-model="groupId"
          name="groupId"
          type="hidden"
        >
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
        >
          Submit
        </el-button>
        <el-button @click="resetForm('ruleForm')">
          Reset
        </el-button>
      </el-form-item>
  </el-col>
</el-row>
    </el-form>
</template>
<script>
import AuthService from "@/api/auth.service";
import DdfgroupService from "@/api/ddfgroup.service";
import authHeader from '@/api/auth-header';

  export default {
    data() {
      var checkName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter Name.'));
        } else {
          callback();
        }
      };
      var checkSlug = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Slug.'));
        } else {
          callback();
        }
      };
      return {
        showAlertAdded: false,
        showAlertSlugexit: false,
        labelPosition: 'top',
        groupId: this.$route.params.groupId,
        imageUploadUrl: process.env.VUE_APP_BASE_API,
        imageUploadHeaders: null,

		selectbathrooms:[{
          value: 'All',
          label: 'All'
        }, {
          value: '1',
          label: '1'
        }, {
          value: '2',
          label: '2'
        }, {
          value: '3',
          label: '3'
        }, {
          value: '4',
          label: '4'
        }, {
          value: '5',
          label: '5'
        }, {
          value: '6',
          label: '6'
        }, {
          value: '7',
          label: '7'
        }, {
          value: '8',
          label: '8'
        }, {
          value: '9',
          label: '9'
        }, {
          value: '10',
          label: '10'
        }, {
          value: '11',
          label: '11'
        }],
    selectbedrooms:[{
          value: 'All',
          label: 'All'
        }, {
          value: '1',
          label: '1'
        }, {
          value: '2',
          label: '2'
        }, {
          value: '3',
          label: '3'
        }, {
          value: '4',
          label: '4'
        }, {
          value: '5',
          label: '5'
        }, {
          value: '6',
          label: '6'
        }, {
          value: '7',
          label: '7'
        }, {
          value: '8',
          label: '8'
        }, {
          value: '9',
          label: '9'
        }, {
          value: '10',
          label: '10'
        }, {
          value: '11',
          label: '11'
        }],
      selectstatus:[{
        value: 'Enabled',
        label: 'Enabled'
      }, {
        value: 'Disabled',
        label: 'Disabled'
      }],
     selectinfluences:[{
        value: 'All',
        label: 'All'
      }, {
        value: 'Golf Course',
        label: 'Golf Course'
      }, {
        value: 'Park',
        label: 'Park'
      }, {
        value: 'Playground',
        label: 'Playground'
      }, {
        value: 'Public Transit',
        label: 'Public Transit'
      }, {
        value: 'Shoppingt',
        label: 'Shopping'
      }],
    selectrental_type:[{
      value: 'All',
      label: 'All'
    }, {
      value: 'For lease',
      label: 'For lease'
    }, {
      value: 'For sale',
      label: 'For sale'
    }],
    selectlisted_on:[{
          value: '',
          label: ''
        },{
          value: '1',
          label: 'Just Listed Today'
        }, {
          value: '7',
          label: 'Just Listed This Week'
        }],
      selectlisting_type:[{
          value: 'All',
          label: 'All'
        }, {
          value: 'Agriculture',
          label: 'Agriculture'
        }, {
          value: 'Business',
          label: 'Business'
        },{
          value: 'Industrial',
          label: 'Industrial'
        },{
          value: 'Multi-Family',
          label: 'Multi-Family'
        },{
          value: 'Office',
          label: 'Office'
        },{
          value: 'Recreational',
          label: 'Recreational'
        },{
          value: 'Retail',
          label: 'Retail'
        },{
          value: 'Single Family',
          label: 'Single Family'
        },{
          value: 'Vacant Land',
          label: 'Vacant Land'
        }],
      select_type:[{
          value: 'All',
          label: 'All'
        }, {
          value: 'Apartment',
          label: 'Apartment'
        }, {
          value: 'Commercial Mix',
          label: 'Commercial Mix'
        },{
          value: 'Duplex',
          label: 'Duplex'
        },{
          value: 'House',
          label: 'House'
        },{
          value: 'Multi-Family',
          label: 'Multi-Family'
        },{
          value: 'No Building',
          label: 'No Building'
        },{
          value: 'Other',
          label: 'Other'
        },{
          value: 'Retail',
          label: 'Retail'
        },{
          value: 'Row / Townhouse',
          label: 'Row / Townhouse'
        }],
        fileList: [],
        data: {
          group_name: null,
          zonegroup: null,
          listed_on:null,
          city: null,
          bedrooms: null,
          bathrooms: null,
          type: null,
          listing_type: null,
          rental_type: null,
          group_description: null,
          footer_description: null,
          lotsize: null,
          min_price: null,
          max_price:null,
          surrounding_area: null,
          propertyaddress: null,
          site_influences: null,
          listing_grouplogo: null,
          meta_title: null,
          meta_description: null,
          meta_keyword: null,
          listing_groupslug: null,
          status: null,
        },
        // ruleForm: {
        //   name: '',
        //   image: ''
        // },
        rules: {
          group_name: [
            { validator: checkName, trigger: 'blur' }
          ],
          listing_groupslug: [
            { validator: checkSlug, trigger: 'blur' }
          ]
        }
      };
    },
	mounted() {
    this.setImageUploadUrl()
    if(this.groupId){
      this.getdetail();
    }
  },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid && !this.groupId) {
             return DdfgroupService.add(this.data).then(response => {
                this.showAlertAdded=true;
              });
          } else if (valid && this.groupId) {
            return DdfgroupService.update(this.groupId, this.data).then(response => {
              this.showAlertAdded=true;
            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },handleFileUpload(){

      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
	backonlist(){
		this.$router.push("/ddfgroup");
	},
	getdetail(){
    return DdfgroupService.get(this.groupId).then(response => {
        this.data = response.data.data;
        this.fileList.push({name:'', url: response.data.data.image});
      });
	},
  setImageUploadUrl() {
        this.imageUploadHeaders = authHeader();
        this.imageUploadUrl = process.env.VUE_APP_BASE_API + 'image-upload';
      },
      handleImageUploadSuccess(response, file, fileList) {
        if(response.code == 200) {
          this.data.listing_grouplogo = response.image_url;
        }
      },
      handleImageUploadError(err, file, fileList) {
        console.log(err)
      },
      handlePreview(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemove(file, fileList) {
        console.log(file, fileList)
        //TODO: Delete the file
      },
   
  }
}
</script>
<style lang="scss" scoped>
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
.form-inputcheck{float:left}
</style>